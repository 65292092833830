.logo {
    align-self: center;
    font-size: 70px;
    font-family: 'Viga', sans-serif;
    position: relative;
}

.myLogo {
    width: 110px;
    height: 110px;
    background-color: #8DE4FF;
    border-radius: 50%;
    border: solid black 5px;
}

#E {
    position: absolute;
    top: -11px;
    left: 20px
}

#L {
    position: absolute;
    top: 30px;
    left:50px;
}

.headerLink {
    text-decoration: none;
    color: black;
}

.headerLink:hover {
    text-decoration: underline;
}
.tech {
    grid-area: tech;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 24px;
    display: none;
}

.projects {
    grid-area: projects;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 24px;
    display: none;
}

.contact {
    grid-area: contact;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 24px;
    display: none;
}

.headerContainer {
    width: 100%;
    height: 140px;
    background-color: #908F8E;
    display: grid;
    grid-template-columns: auto max-content;
    padding: 0 32px;
    align-items: center;
}

.headerContainer a {
    color: black;

}

.headerContainer a:hover {
    text-decoration: underline;
    
}

@media screen and (min-width: 700px) {
    .logo {
        grid-area: logo;
    }

    .tech, .projects, .contact {
        display: block;
    }

    .headerContainer {
        grid-template-columns: 200px auto max-content max-content max-content;
        grid-template-rows: 25% auto 25%;
        grid-template-areas: 
            " logo . . . . " 
            " logo . tech projects contact  "
            " logo . . . .  ";
        grid-gap: 12px;
        align-items: center;
        padding: 0 100px;
    }
}