#projects {
    grid-area: projects;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 30px;
    align-self: center;
}

.flip-container {
    perspective: 1000px;
    align-items: center;
    justify-items: center;
    margin: 20px;
}

.flip-container:hover .flipper, .flip-container.hover .flipper {
    transform: rotateY(180deg);
}

.flip-container, .front, .back {
    width: 500px;
    height: 300px;
}

.flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
}

.front, .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 4px 4px 8px -4px #333;
}

.front {
    z-index: 2;
}

.back {
    transform: rotateY(180deg);
    background-color: white;
}

h4 {
    color: #54B689;
}

h5 {
    color: #00BCF5;
}


.pictureSaver {
    grid-area: pictureSaver;
}

.picimg {
    width: 500px;
    height: 300px;
    object-fit: cover;
}

.falendar {
    grid-area: falendar;
}

.falimg {
    width: 500px;
    height: 300px;
    object-fit: cover;
}

.motivationalPuppy {
    grid-area: motivationalPuppy;
}

.motimg {
    width: 500px;
    height: 300px;
    object-fit: cover;
}

.bunnyChase {
    grid-area: bunnyChase;
}

.chaseimg {
    width: 500px;
    height: 300px;
    object-fit: cover;
}

.projectsContainer {
    background-color: #87CBAC;
    display: grid;
    grid-template-areas: 
    " projects "
    " pictureSaver "
    " falendar "
    " motivationalPuppy "
    " bunnyChase ";
    justify-content: center;
}

.projectsContainer a:hover {
    text-decoration: underline;
}

@media screen and (min-width: 700px) {
    .projectsContainer {
        grid-template-columns: 100px auto auto 100px;
        grid-template-rows: 100px auto auto 100px;
        grid-template-areas: 
        " . projects projects . "
        " . pictureSaver falendar . "
        " . motivationalPuppy bunnyChase . ";
    }
    
}