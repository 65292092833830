.treeimg{
    width: 100%;
    object-fit: cover;
}

.title{
    font-family: sans-serif;
    font-size: 30px;
    position: absolute;
    top: 182px;
    background: rgba(0, 0, 0, 0.36);
    color: lightgrey;
    left: 0;
    right: 0;
    padding: 12px;
}

.name{
    font-weight: bold;
    color: #54B689;
}

.job{
    font-weight: bolder;
    color: #00BCF5;
}

.personality {
    font-family: sans-serif;
    font-size: 30px;
    position: absolute;
    top: 300px;
    background: rgba(0, 0, 0, 0.36);
    color: lightgrey;
    left: 42px;
    right: 42px;
    padding: 12px;
    text-align: left;
}

.seeking {
    padding: 32px;
    font-family: sans-serif;
    font-size: 30px;
    text-align: left;
}

.container {
    background-color: #CDCCCB;
    
       
}

@media screen and (min-width:700px) {
    
    .container {
        height: 84vh;
        display: grid;
        grid-template-columns: 100px auto auto auto auto 100px;
        grid-template-rows: 80px auto auto auto 20px;
        grid-template-areas: 
            " treeImage treeImage . . . . " 
            " treeImage treeImage title title title . "
            " treeImage treeImage . personality personality . "
            " treeImage treeImage seeking seeking seeking . "
            " treeImage treeImage . . . . ";
        justify-items: start;
        position: relative;
    }

    .treeimg{
        grid-area: treeImage;
        height: 84vh;
        width: 300px;
        object-fit: cover;
    }

    .title{
        grid-area: title;
        padding-left: 25px;
        font-size: 30px;
        position: static
    }

    .personality {
        grid-area: personality;
        position: static;
        background: none;
        color: inherit;
    }

    .seeking {
        grid-area: seeking;
        text-align: left;
        padding-left: 25px;
        font-family: sans-serif;
        font-size: 30px;
    }
}

